import { useEffect, useState } from "react";

function Filters({ activeCategory, setActiveCategory, fetchProducts }) {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await fetch('https://sitepro2.kz/api/product/categories');
            const responseJson = await response.json();
            if (responseJson.success) {
                setCategories(responseJson.categories);
            }
        } catch (error) {
            console.log('fetch categories error: ', error);
        }
    };

    const selectCategory = (category) => {
        if (activeCategory === category) {
            setActiveCategory(''); // Сбрасываем выбор категории, если она уже выбрана
        } else {
            setActiveCategory(category); // Устанавливаем новую активную категорию
        }
        fetchProducts(); // Вызываем обновление списка товаров
    };

    return (
        <div className="w-full p-2 justify-center items-center flex py-6 mobile:pt-24 tablet:pt-28">
            <div className="mobile:w-full laptop:w-6/12 flex flex-row flex-wrap justify-center items-center">
                {categories.map((item, index) => (
                    <button
                        onClick={() => selectCategory(item.title)}
                        key={index}
                        className={
                            activeCategory === item.title ?
                                "bg-[#4534FF] mobile:p-1 laptop:p-2 mobile:px-2 laptop:px-4 rounded-xl justify-center items-center flex m-1.5 group hover:bg-[#EFEFEF]" :
                                "bg-[#EFEFEF] mobile:p-1 laptop:p-2 mobile:px-2 laptop:px-4 rounded-xl justify-center items-center flex m-1.5 group hover:bg-[#4534FF]"
                        }
                    >
                        <p
                            className={
                                activeCategory === item.title ?
                                    "mobile:text-lg laptop:text-xl text-white group-hover:text-[#141414]" :
                                    "mobile:text-lg laptop:text-xl text-[#141414] group-hover:text-white"
                            }
                        >
                            {item.title}
                        </p>
                    </button>
                ))}
            </div>
        </div>
    )
};

export default Filters;
