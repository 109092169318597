import Navbar from "../ux/Navbar";
import Filters from "../ux/Filters";
import Cards from "../ux/Cards";
import Footer from "../ux/Footer";
import ReactLoading from 'react-loading';
import { useEffect, useState } from "react";

function CatalogPage() {
    const [products, setProducts] = useState([]);
    const [activeCategory, setActiveCategory] = useState('');
    const [isLoad, setIsLoad] = useState(false);

    useEffect(() => {
        fetchProducts();
    }, [activeCategory]);

    const fetchProducts = async () => {
        try {
            setIsLoad(true);
            const response = await fetch('https://sitepro2.kz/api/product/products', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ category: activeCategory })
            });

            const responseJson = await response.json();
            console.log("responseJson: ", responseJson);
            if (responseJson.success) {
                console.log("responseJson: ", responseJson.items);
                setProducts(responseJson.items);
            }
        } catch (error) {
            console.log(`fetch products error: ${error}`);
        } finally {
            setIsLoad(false);
        }
    };

    return (
        <div className="w-full h-full bg-[#F9F9F9]">
            <Navbar />
            <div>
                <Filters activeCategory={activeCategory} setActiveCategory={setActiveCategory} fetchProducts={fetchProducts} />
            </div>
            {isLoad && (
                <div className="h-[60vh] w-full justify-center items-center flex flex-col space-y-2">
                    <ReactLoading type="spin" color="#4534FF" height={25} width={25} />
                    <p className="text-md text-[#141414]/50">Подождите, это может занять немного времени</p>
                </div>
            )}
            {!isLoad && (
                <>
                    {products.length > 0 ? (
                        <Cards products={products} />
                    ) : (
                        <div className="h-[60vh] w-full justify-center items-center flex flex-col space-y-2">
                            <p className="text-md text-[#141414]/50">По данному запросу ничего не найдено</p>
                        </div>
                    )}
                </>
            )}
            <Footer />
        </div>
    )
};


export default CatalogPage;