import { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";

function LeftMenu({ openCart, onClose }) {
    const [isLogged, setIsLogged] = useState(false);

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    useEffect(() => {
        const authToken = getCookie("auth_token");
        setIsLogged(!!authToken); 
    }, []);

    useEffect(() => {
        document.body.classList.add('overflow-hidden');

        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, []);

    const logout = async () => {
        // Устанавливаем дату истечения срока действия в прошлом для удаления cookie
        onClose();
        document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
        window.location.reload();
    };

    return (
        <div className="w-full h-screen fixed z-30 overflow-x-hidden overflow-y-hidden">
            <button onClick={onClose} className="w-full h-screen absolute z-40 bg-black/20"/>
            <div className="h-screen w-64 bg-white p-4 absolute z-50 right-0 flex flex-col">
                <div className="flex flex-row space-x-2 items-center justify-center">
                    <button onClick={onClose} className="hover:opacity-50">
                        <IoIosArrowBack className="text-xl text-[#141414]"/>
                    </button>
                    <p className="text-2xl text-[#141414]">Site.<span className="text-[#4534FF]">pro</span></p>
                </div>
                <p className="text-lg text-[#141414] text-center">Чтобы использовать все возможности сайта выполните</p>
                { !isLogged && (
                    <div className="flex flex-row justify-between items-center w-full mt-4">
                        <Link to='/auth' onClick={() => onClose()} className="hover:opacity-50 border-2 border-[#4534FF4D]/30 justify-center items-center px-2 p-1 rounded-full">
                            <p className="text-md text-[#4534FF]">Войти</p>
                        </Link>
                        <p className="text-lg text-[#141414]">или</p>
                        <Link to='/reg' onClick={() => onClose()} className="hover:opacity-50 bg-[#4534FF] justify-center items-center px-2 p-1 rounded-full">
                            <p className="text-md text-white">Регистрация</p>
                        </Link>
                    </div>
                ) }
                <div className="mt-6 flex flex-col items-start space-y-2 flex-1">
                    <Link to='/catalog' onClick={() => onClose()} className="hover:opacity-50">
                        <p className="text-xl text-[#141414]">Каталог</p>
                    </Link>
                    <Link to='/about-us' onClick={() => onClose()} className="hover:opacity-50">
                        <p className="text-xl text-[#141414]">О нас</p>
                    </Link>
                    <Link to='/contacts' onClick={() => onClose()} className="hover:opacity-50">
                        <p className="text-xl text-[#141414]">Контакты</p>
                    </Link>
                    { isLogged && (
                        <>
                            <Link to='/favourite' onClick={() => onClose()} className="hover:opacity-50">
                                <p className="text-xl text-[#141414]">Избранное</p>
                            </Link>
                            <Link onClick={() => {onClose(); openCart();}} className="hover:opacity-50">
                                <p className="text-xl text-[#141414]">Корзина</p>
                            </Link>
                            <button onClick={() => logout()} className="hover:opacity-50">
                                <p className="text-xl text-red-500">Выйти</p>
                            </button>
                        </>
                    ) }
                </div>
                <div className="flex flex-col space-y-1">
                    <p className="text-lg text-[#141414]/70">© Copyright 2024 Site.pro</p>
                    <p className="text-lg text-[#141414]/70">Политика конфиденциальности</p>
                    <p className="text-lg text-[#141414]/70">Публичная Оферта</p>
                </div>
            </div>
        </div>
    );
};

export default LeftMenu;
