import { CiSearch, CiHeart  } from "react-icons/ci";
import { BsCart2 } from "react-icons/bs";
import { useEffect, useState } from "react";
import CartModal from "./CartModal";
import { Link, useNavigate } from "react-router-dom";
import LeftMenu from "./LeftMenu";
import { FiMenu } from "react-icons/fi";
import { MdOutlineClose } from "react-icons/md";
import SearchBar from "./SearchBar";

function Navbar() {
    const [isOpenCart, setIsOpenCart] = useState(false);
    const navigate = useNavigate();
    const [isOpenLeftMenu, setIsOpenLeftMenu] = useState(false);
    const [isLogged, setIsLogged] = useState(null);
    const [userData, setUserData] = useState(null);
    const [isSearch, setIsSearch] = useState(false);

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    useEffect(() => {
        const authToken = getCookie("auth_token");
        fetchUserData(authToken);
        setIsLogged(!!authToken); 
    }, []);

    const fetchUserData = async (authToken) => {
        try {
            const response = await fetch('https://sitepro2.kz/api/user/user', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}` // Добавляем токен в заголовок
                }
            });
    
            const responseJson = await response.json();
            if (responseJson.success) {
                setUserData(responseJson.user[0]);
                console.log(`user: `, responseJson.user[0]);
            } else {
                console.log('Ошибка: ', responseJson.message);
            }
        } catch (error) {
            console.log('fetch user data error: ', error);
        }
    };
    

    return (
        <>
            <div className="w-full bg-white p-6 mobile:px-6 laptop:px-12 flex flex-row justify-between items-center z-20 fixed">
                <div className="flex flex-row items-center mobile:justify-between mobile:w-full laptop:w-fit laptop:justify-center mobile:space-x-0 laptop:space-x-12">
                    <div className="flex flex-row items-center justify-center space-x-2">
                        {/* Бургер-меню, скрыто при активном поиске на мобильных устройствах */}
                        <button 
                            onClick={() => setIsOpenLeftMenu(true)} 
                            className={`hover:opacity-50 ${isSearch ? 'hidden' : 'mobile:visible laptop:hidden'}`}
                        >
                            <FiMenu className="text-3xl text-[#141414]"/>
                        </button>
                        {/* Логотип, скрыт при активном поиске на мобильных устройствах, но виден на планшетах и более крупных экранах */}
                        <p className={`mobile:text-2xl tablet:text-4xl text-[#141414] ${isSearch ? 'hidden tablet:flex' : ''}`}>
                            Site.<span className="text-[#4534FF]">pro</span>
                        </p>
                    </div>
                    <div className="flex-row items-center space-x-6 mobile:hidden laptop:flex">
                        <Link to='/catalog' className="hover:opacity-50">
                            <p className="text-[#141414] text-xl">Каталог</p>
                        </Link>
                        <Link to='/about-us' className="hover:opacity-50">
                            <p className="text-[#141414] text-xl">О нас</p>
                        </Link>
                        <Link to='/contacts' className="hover:opacity-50">
                            <p className="text-[#141414] text-xl">Контакты</p>
                        </Link>
                    </div>
                </div>
                <div className="flex flex-row space-x-6 items-center">
                    <div className="flex flex-row items-center space-x-4">
                        <SearchBar isSearch={isSearch} setIsSearch={setIsSearch}/>
                        { isLogged ? (
                            <>
                                <Link to='/favourite' className="h-10 w-10 bg-[#EFEFEF] justify-center items-center flex rounded-full hover:opacity-50 mobile:hidden laptop:flex">
                                    <CiHeart className="text-xl text-black"/>
                                </Link>
                                <button onClick={() => setIsOpenCart(true)} className="h-10 w-10 bg-[#EFEFEF] justify-center items-center flex rounded-full hover:opacity-50 mobile:hidden laptop:flex">
                                    <BsCart2 className="text-xl text-black"/>
                                </button>
                                <Link to='/profile' className="flex-shrink-0 w-10 h-10 bg-[#EFEFEF] rounded-full">
                                    <img src={`https://sitepro2.kz/api/user/photo/${userData?.photo_profile}`} className="w-full h-full rounded-full" alt="photo_profile"/>
                                </Link>
                            </>
                        ) : (
                            <>
                                <button onClick={() => navigate('/auth')} className="h-10 px-6 hover:opacity-50 border-[1px] border-[#4534FF] rounded-full mobile:hidden tablet:block">
                                    <p className="text-xl text-[#4534FF] font-light">Войти</p>
                                </button>
                                <button onClick={() => navigate('/reg')}  className="h-10 px-6 hover:opacity-50 bg-[#4534FF] rounded-full mobile:hidden tablet:block">
                                    <p className="text-xl text-white font-light">Регистрация</p>
                                </button>
                            </>
                        ) }
                    </div>
                </div>
            </div>
            { isOpenCart && <CartModal onClose={() => setIsOpenCart(false)}/> }
            { isOpenLeftMenu && <LeftMenu openCart={() => setIsOpenCart(true)} onClose={() => setIsOpenLeftMenu(false)}/> }
        </>
    )
};

export default Navbar;