import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import ReactLoading from 'react-loading';

function CartModal({ onClose }) {
    const [cart, setCart] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [totalCartItems, setTotalCartItems] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        fetchCart();
    }, []);

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    const fetchCart = async () => {
        try {
            setIsLoad(true);
            const authToken = getCookie("auth_token");
            const response = await fetch('https://sitepro2.kz/api/user/cart', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
    
            const responseJson = await response.json();
    
            if (responseJson.success) {
                const items = responseJson.items;
                setCart(items);
                setTotalCartItems(items.length);
    
                // Расчет общей стоимости
                const total = items.reduce((acc, item) => acc + parseInt(item.price, 10), 0);
                setTotalPrice(total);
                
            }
        } catch (error) {
            console.log('fetch cart error: ', error);
        } finally {
            setIsLoad(false);
        }
    };
    
    const deleteFromCart = async (productId) => {
        try {
            const authToken = getCookie("auth_token");
            const response = await fetch(`https://sitepro2.kz/api/user/cart/delete/${productId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });

            const responseJson = await response.json();

            if (responseJson.success) {
                fetchCart();
            }
        } catch (error) {
            console.log('delete from cart error: ', error);
        }
    };

    useEffect(() => {
        document.body.classList.add('overflow-hidden');

        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, []);

    return (
        <div className="w-full h-screen fixed z-30 overflow-x-hidden overflow-y-hidden">
            <button onClick={onClose} className="w-full h-screen absolute z-40 bg-black/20"/>
            <div className="h-screen mobile:w-full tablet:w-10/12 laptop:w-7/12 desktop:w-3/12 bg-white p-6 absolute z-50 right-0 flex flex-col">
                {/* Заголовок корзины */}
                <div className="w-full flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center justify-center space-x-4">
                        <p className="text-3xl text-[#141414]">Корзина</p>
                        { totalCartItems > 0 && <p className="text-xl text-[#141414]/50">{totalCartItems} товара</p> }
                    </div>
                    <button onClick={onClose} className="w-10 h-10 bg-[#EFEFEF] rounded-full flex justify-center items-center hover:opacity-50">
                        <MdClose className="text-xl text-[#141414]"/>
                    </button>
                </div>
                
                {/* Основное содержимое корзины */}
                { !isLoad && (
                    <>
                        { cart && cart?.length > 0 ? (
                            <>
                                <div className="flex-1 overflow-y-auto mt-4 space-y-4 cart">
                                    { cart.map((item, index) => (
                                        <div key={index} className="flex flex-row justify-between items-start">
                                            <div className="flex flex-row space-x-2">
                                                <img src={`https://sitepro2.kz/api/product/previewImage/${item.image_preview}`} className="mobie:w-12 tablet:w-48 mobile:h-24 tablet:h-32 rounded-xl bg-[#BDBDBD] flex-shrink-0 object-cover" alt="preview-image"/>
                                                <div>
                                                    <p className="mobile:text-xs tablet:text-md text-[#141414] w-32">{item.title}</p>
                                                    <p className="mobile:text-xs tablet:text-md text-[#141414]/50">Стоимость <span className="text-[#141414]">{item.price}</span></p>
                                                    <p className="mobile:text-xs tablet:text-md text-[#141414]/50">Описание <span className="text-[#141414]">{item.description}</span></p>
                                                </div>
                                            </div>
                                            <button onClick={() => deleteFromCart(item.product_id)} className="hover:opacity-50">
                                                <MdClose className="text-2xl text-[#141414]"/>
                                            </button>
                                        </div>
                                    )) }
                                </div>
                                <div className="info mt-4">
                                    <div className="flex flex-row justify-between items-center">
                                        <p className="text-2xl text-[#141414] font-semibold">Сумма</p>
                                        <p className="text-2xl text-[#141414] font-semibold">{totalPrice} тг</p>
                                    </div>
                                    <button className="bg-[#4534FF] justify-center items-center flex p-2 rounded-xl w-full mt-4">
                                        <p className="text-2xl text-white">Оплатить</p>
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className="w-full flex-1 justify-center items-center flex">
                                <p className="text-xl text-[#141414] text-center">Пока товаров нет в корзине</p>
                            </div>
                        ) }
                    </>
                ) }

                { isLoad && (
                    <div className="flex flex-row justify-center items-center w-full flex-1">
                        <ReactLoading type="spin" color="#4534FF" height={25} width={25} />
                    </div>
                ) }
            </div>
        </div>
    );
};

export default CartModal;
