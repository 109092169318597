import Navbar from "../ux/Navbar"
import Form from "../ux/Form"
import Footer from "../ux/Footer"

function AboutUs() {
    return (
        <div className="w-full h-full bg-[#F9F9F9]">
            <Navbar/>
            <div className="w-full flex flex-row justify-between items-center mobile:py-24 laptop:py-28">
                <img src={require("../../img/left.png")} alt="left" className="h-screen mobile:hidden tablet:block"/>
                <div className="mobile:w-full tablet:w-5/12 flex flex-col space-y-4 mobile:px-6 tablet:px-0">
                    <p className="mobile:text-xl tablet:text-2xl laptop:text-3xl text-[#141414] font-semibold">Наша компания специализируется на создании и продаже готовых сайтов, предоставляя клиентам быстрые и удобные решения для их онлайн-присутствия.</p>
                    <p className="mobile:text-xl tablet:text-2xl laptop:text-3xl text-[#4534FF] font-semibold">Мы гордимся современным дизайном и функциональностью наших сайтов, разработанных командой опытных специалистов.</p>

                    <p className="mobile:text-xl tablet:text-2xl laptop:text-3xl text-[#141414] font-semibold">Каждый проект мы адаптируем под уникальные потребности вашего бизнеса.</p>
                    <p className="mobile:text-xl tablet:text-2xl laptop:text-3xl text-[#4534FF] font-semibold">Одним из наших ключевых преимуществ является скорость: вы получаете готовый сайт в кратчайшие сроки, что позволяет сразу начать онлайн-деятельность.</p>

                    <p className="mobile:text-xl tablet:text-2xl laptop:text-3xl text-[#141414] font-semibold">Мы предлагаем широкий выбор готовых сайтов, легко адаптируемых под ваши нужды, и все наши сайты оптимизированы для поисковых систем (SEO), что улучшает видимость вашего бизнеса в интернете.</p>
                    <p className="mobile:text-xl tablet:text-2xl laptop:text-3xl text-[#4534FF] font-semibold">Наша компания обеспечивает бесплатную техническую поддержку в течение первых шести месяцев после покупки сайта. Прозрачные цены и отсутствие скрытых затрат делают сотрудничество комфортным и предсказуемым.</p>
                </div>
                <img src={require("../../img/left.png")} alt="left" className="h-screen mobile:hidden tablet:block rotate-180"/>
            </div>
            <Form/>
            <Footer/>
        </div>
    )
};

export default AboutUs