import Card from "./Card";

function Cards({ products }) {
    return (
        <div className="w-full h-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-6">
            { products.map((item, index) => (
                <Card key={index} data={item}/>
            )) }
        </div>
    );
};

export default Cards;
