import Navbar from "../ux/Navbar";
import Cards from "../ux/Cards";
import Footer from "../ux/Footer";
import { useEffect, useState } from "react";
import ReactLoading from 'react-loading';

function FavouritePage() {
    const [favourite, setFavourite] = useState([]);
    const [isLoad, setIsLoad] = useState(false);

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    useEffect(() => {
        fetchFavouriteList();
    }, []);

    const fetchFavouriteList = async () => {
        try {
            setIsLoad(true);
            const authToken = getCookie("auth_token");
            const response = await fetch('https://sitepro2.kz/api/user/favourite/list', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
    
            const responseJson = await response.json();
    
            if (responseJson.success) {
                const items = responseJson.items;
                setFavourite(items);
            }
        } catch (error) {
            console.log('fetch cart error: ', error);
        } finally {
            setIsLoad(false);
        }
    }
    return (
        <div className="w-full h-full bg-[#F9F9F9]">
            <Navbar/>
            <div className="pt-28 mb-4 px-6">
                <p className="text-4xl text-[#141414] font-semibold">Избранное</p>
            </div>
            { isLoad && (
                <div className="h-[60vh] w-full justify-center items-center flex flex-col space-y-2">
                    <ReactLoading type="spin" color="#4534FF" height={25} width={25} />
                    <p className="text-md text-[#141414]/50">Подождите, это может занять немного времени</p>
                </div>
            ) }
            { !isLoad && (
                <>
                    { favourite && favourite?.length > 0 ? (
                        <Cards products={favourite} />
                    ) : (
                        <div className="h-[60vh] w-full justify-center items-center flex flex-col space-y-2">
                            <p className="text-md text-[#141414]/50">У вас пока нет избранных товаров</p>
                        </div>
                    ) }
                </>
            ) }
            <Footer/>
        </div>
    )
};

export default FavouritePage;