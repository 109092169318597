import { useState, useEffect } from "react";
import { MdOutlineClose } from "react-icons/md";
import { CiSearch  } from "react-icons/ci";
import ReactLoading from 'react-loading';

function SearchBar({ isSearch, setIsSearch }) {
    const [search, onChangeSearch] = useState();
    const [searchResult, setSearchResult] = useState([]);
    const [isLoad, setIsLoad] = useState(false);

    const handleChangeSearch = (text) => {
        onChangeSearch(text);
        fetchSearchResult(text)
    }

    const fetchSearchResult = async (searchQuery) => {
        setSearchResult([]);  // Очищаем предыдущие результаты перед новым запросом
    
        if (!searchQuery.trim()) {
            return; // Если запрос пустой или состоит только из пробелов, не делаем запрос
        }
    
        try {
            setIsLoad(true);
            const response = await fetch('https://sitepro2.kz/api/product/search', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ searchQuery })
            });
    
            const responseJson = await response.json();
    
            if (responseJson.success) {
                setSearchResult(responseJson.items);
            }
        } catch (error) {
            console.log('fetch search error: ', error);
        } finally {
            setIsLoad(false);
        }
    };
    
    const clearSearch = () => {
        onChangeSearch("");
        setSearchResult([]);
    };
    
    return (
        <>
            { isSearch && (
                <div className="relative mobile:w-56 tablet:w-96">
                    <div className={`bg-[#EFEFEF] rounded-full w-full h-10 px-4 flex flex-row items-center justify-between ${ search && 'border-2 border-[#4534FF]' }`}>
                        <div className="flex flex-row space-x-2 items-center">
                            <CiSearch className="text-2xl text-black"/>
                            <input
                                value={search}
                                onChange={(e) => handleChangeSearch(e.target.value)}
                                placeholder="Начните поиск"
                                className="outline-none bg-transparent placeholder:text-[#141414] text-[#141414] text-lg w-full"
                            />
                        </div>
                        { search && (
                            <button onClick={() => clearSearch()} className="hover:opacity-50">
                                <MdOutlineClose className="text-xl text-black"/>
                            </button>
                        ) }
                    </div>
                    { search && (
                        <div className="w-full bg-white p-4 border-2 border-[#4534FF] absolute z-10 mt-6 space-y-2 flex flex-col rounded-xl">
                            { isLoad && (
                                <div className="h-24 justify-center items-center flex">
                                    <ReactLoading type="spin" color="#4534FF" height={25} width={25} />
                                </div>
                            ) }
                            { !isLoad && (
                                <>
                                    { searchResult && searchResult?.length > 0 ? (
                                        <>
                                            { searchResult.map((item, index) => (
                                                <button key={index} className="flex flex-row w-full justify-between items-center hover:opacity-50">
                                                    <div className="flex flex-col">
                                                        <p className="mobile:text-md tablet:text-lg text-[#141414] text-left">{item.title}</p>
                                                        <p className="mobile:text-xs tablet:text-md text-[#141414]/50 text-left">{item.category}</p>
                                                    </div>
                                                    <div className="flex flex-row space-x-2">
                                                        { item.old_price && <p className="mobile:text-xs tablet:text-lg text-[#141414]/50 line-through text-nowrap">{item.old_price} тг</p> }
                                                        <p className="mobile:text-xs tablet:text-lg text-[#141414] text-nowrap">{item.price} тг</p>
                                                    </div>
                                                </button>
                                            )) }
                                        </>
                                    ) : (
                                        <div className="w-full h-12 flex justify-center items-center">
                                            <p className="text-lg text-[#141414]">По данному запросу ничего не найдено</p>
                                        </div>
                                    ) }
                                </>
                            ) }
                        </div>
                    ) }
                </div>
            ) }
            { isSearch ? (
                <button onClick={() => setIsSearch(false)} className="h-10 w-10 bg-[#EFEFEF] justify-center items-center flex rounded-full hover:opacity-50">
                    <MdOutlineClose className="text-xl text-black"/>
                </button>
            ) : (
                <button onClick={() => setIsSearch(true)} className="h-10 w-10 bg-[#EFEFEF] justify-center items-center flex rounded-full hover:opacity-50">
                    <CiSearch className="text-xl text-black"/>
                </button>
            ) }
        </>
    )
};

export default SearchBar;