import { useState } from "react";

function EditPassword({ onClose }) {
    const [data, setData] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    });
    const [message, setMessage] = useState("");

    const handleChangeData = (e) => {
        const { name, value } = e.target;
        setData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    const saveNewPassword = async () => {
        if (data.confirmPassword === data.newPassword) {
            setMessage();
            if (data.confirmPassword.length > 8) {
                setMessage();
                try {
                    const authToken = getCookie("auth_token"); 
                    const response = await fetch('https://sitepro2.kz/api/user/updatePassword', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${authToken}`
                        },
                        body: JSON.stringify({ 
                            oldPassword: data.oldPassword,
                            newPassword: data.newPassword
                        })
                    });
        
                    const responseJson = await response.json();
        
                    console.log("responseJson: ", responseJson)
                    if (responseJson.success) {
                        setMessage();
                        onClose();
                    } else {
                        setMessage(responseJson.message);
                    }
                } catch (error) {
                    console.log('save new password: ', error);
                }
            } else {
                setMessage("Новый пароль должен быть свыше 8 символов");
            }
        } else {
            setMessage('Пароли не совпадают')
        }
    }

    return (
        <div className="w-full h-full fixed inset-0 z-50 flex items-center justify-center">
            <div className="bg-black/50 absolute inset-0" onClick={onClose}/>
            <div className="bg-white w-96 p-8 rounded-2xl z-10">
                <h2 className="text-xl font-semibold mb-4 text-[#141414] text-center">Смена пароля</h2>
                <p className="text-lg text-[#141414] font-semibold mb-1">Старый пароль</p>
                <input
                    type="password"
                    placeholder="Старый пароль"
                    className="w-full p-2 px-3 mb-4 border-[1px] border-[#141414]/50 rounded-xl text-lg outline-none bg-transparent text-[#14141]/50 placeholder:text-[#141414]/50"
                    value={data.oldPassword}
                    name="oldPassword"
                    onChange={handleChangeData}
                />
                <p className="text-lg text-[#141414] font-semibold mb-1">Новый пароль</p>
                <input
                    type="password"
                    placeholder="Новый пароль"
                    className="w-full p-2 px-3 mb-4 border-[1px] border-[#141414]/50 rounded-xl text-lg outline-none bg-transparent text-[#14141]/50 placeholder:text-[#141414]/50"
                    value={data.newPassword}
                    name="newPassword"
                    onChange={handleChangeData}
                />
                <p className="text-lg text-[#141414] font-semibold mb-1">Подтвердите пароль</p>
                <input
                    type="password"
                    placeholder="Подтвердите пароль"
                    className="w-full p-2 px-3 mb-4 border-[1px] border-[#141414]/50 rounded-xl text-lg outline-none bg-transparent text-[#14141]/50 placeholder:text-[#141414]/50"
                    value={data.confirmPassword}
                    name="confirmPassword"
                    onChange={handleChangeData}
                />
                <p className="text-xl text-red-500 text-center">{message}</p>
                <button
                    onClick={() => saveNewPassword()}
                    className={`bg-[#4534FF] text-white py-2 px-4 mt-4 w-full ${ !data.oldPassword || !data.newPassword || !data.confirmPassword ? 'opacity-50' : 'hover:opacity-50' } rounded-xl text-lg`}
                    disabled={!data.oldPassword || !data.newPassword || !data.confirmPassword}
                >
                    Сохранить
                </button>
            </div>
        </div>
    );
};

export default EditPassword;
