import Footer from "../ux/Footer";
import Navbar from "../ux/Navbar";
import { CiHeart } from "react-icons/ci";
import { FaHeart } from "react-icons/fa";
import Form from "../ux/Form";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ReactLoading from 'react-loading';

function ProductPage() {
    const { id } = useParams();
    const [productInfo, setProductInfo] = useState(null);
    const [isLoad, setIsLoad] = useState(true);
    const [error, setError] = useState(null);
    const [isFavourite, setIsFavourite] = useState(false);

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    const handleChangeFavourite = async () => {
        try {
            const authToken = getCookie("auth_token");
            const response = await fetch('https://sitepro2.kz/api/user/favourite/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}` // Добавляем токен в заголовок
                },
                body: JSON.stringify({ product_id: id })
            });

            const responseJson = await response.json();

            if (responseJson.success) {
                if (responseJson.message === 'favourite product deleted') {
                    setIsFavourite(false);
                    window.location.reload();
                } else {
                    setIsFavourite(true);
                    window.location.reload();
                }
            }
        } catch (error) {
            console.log('add favourite error: ', error);
        }
    }

    useEffect(() => {
        const fetchProductInfo = async () => {
            try {
                const response = await fetch(`https://sitepro2.kz/api/product/get-product/${id}`);
                const responseJson = await response.json();
                console.log("responseJson product by id: ", responseJson)

                if (responseJson.success && responseJson.product && responseJson.product.length > 0) {
                    console.log("responseJson.product by id: ", responseJson.product)
                    setProductInfo(responseJson.product[0]);
                } else {
                    setProductInfo(null);
                }
            } catch (error) {
                console.log(`fetch product info error: ${error}`);
                setError('Произошла ошибка при загрузке данных.');
            } finally {
                setIsLoad(false);
            }
        }

        fetchProductInfo();
        fetchIsFavourite(id);
        window.scrollTo(0, 0);
    }, [id]);

    const fetchIsFavourite = async (id) => {
        try {
            const authToken = getCookie("auth_token");
            const response = await fetch(`https://sitepro2.kz/api/user/isFavourite/${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${authToken}` // Добавляем токен в заголовок
                }
            });

            const responseJson = await response.json();

            console.log("fetchIsFavourite responseJson: ", responseJson)

            if (responseJson.success) {
                setIsFavourite(responseJson.isFavourite);
            }
        } catch (error) {
            console.log('fetch is favourite error: ', error);
        }
    }

    const addCart = async () => {
        const authToken = getCookie("auth_token");
        if (authToken) {
            try {
                const response = await fetch('https://sitepro2.kz/api/user/cart/add', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}` // Добавляем токен в заголовок
                    },
                    body: JSON.stringify({ product_id: id })
                });

                const responseJson = await response.json();

                if (responseJson.success) {
                    alert("Товар добавлен в корзину");
                } else {
                    if (responseJson.message === 'This product exist in cart') {
                        alert("Товар уже добавлен в корзину");
                    }
                }
            } catch (error) {
                console.log(`add cart error: `, error);
            }
        } else {
            alert("Для добавления в корзину авторизуйтесь")
        }
    }

    const buyProduct = async () => {
        const authToken = getCookie("auth_token");
        if (authToken) {
            //
        } else {
            alert("Для покупки сначала авторизуйтесь");
        }
    }

    return (
        <div className="w-full h-full bg-[#F9F9F9]">
            <Navbar />
            {isLoad && (
                <div className="h-[60vh] w-full justify-center items-center flex flex-col space-y-2">
                    <ReactLoading type="spin" color="#4534FF" height={25} width={25} />
                    <p className="text-md text-[#141414]/50">Подождите, это может занять немного времени</p>
                </div>
            )}
            {!isLoad && (
                <>
                    {error && (
                        <div className="w-full h-[60vh] justify-center items-center">
                            <p className="text-xl text-[#141414]">{error}</p>
                        </div>
                    )}
                    {!error && productInfo === null ? (
                        <div className="w-full h-[100vh] justify-center items-center flex flex-col bg-[#F9F9F9]">
                            <div className="flex flex-row space-x-4 items-center">
                                <p className="text-[250px] font-bold text-[#141414]">4</p>
                                <div className="w-[200px] h-[200px] flex-shrink-0 border-[30px] border-[#4534FF] rounded-full" />
                                <p className="text-[250px] font-bold text-[#141414]">4</p>
                            </div>
                        </div>
                    ) : (
                        <div className="flex justify-center w-full items-center py-12 pt-28">
                            <div className="mobile:w-11/12 tablet:w-10/12 dekstop:w-8/12 flex mobile:flex-col laptop:flex-row mobile:space-x-0 laptop:space-x-6">
                                <img src={`https://sitepro2.kz/api/product/previewImage/${productInfo.image_preview}`} className="w-[700px] mobile:h-[300px] tablet:h-[400px] laptop:h-[500px] desktop:h-[600px] bg-[#BDBDBD] rounded-2xl object-cover" alt="preview_image" />
                                <div className="flex flex-col flex-1">
                                    <div className="flex flex-row items-center justify-between w-full">
                                        <p className="mobile:text-xl tablet:text-2xl text-[#141414] w-96">{productInfo.title}</p>
                                        <button onClick={() => handleChangeFavourite()} className="h-12 w-12 bg-[#EFEFEF] flex justify-center items-center rounded-full hover:opacity-50">
                                            {isFavourite ?
                                                <FaHeart className="text-2xl text-[#4534FF]" /> :
                                                <CiHeart className="text-2xl" />
                                            }
                                        </button>
                                    </div>
                                    <div className="flex flex-row items-center py-2 space-x-4">
                                        {productInfo.old_price && <p className="text-xl text-[#141414]/30 line-through">{productInfo.old_price} тг</p>}
                                        <p className="text-xl text-[#141414]">{productInfo.price} тг</p>
                                    </div>
                                    <div className="flex mobile:flex-col tablet:flex-row items-center mobile:space-y-2 tablet:space-y-0 mobile:space-x-0 tablet:space-x-4">
                                        <button onClick={buyProduct} className="bg-[#4534FF] p-2 px-4 mobile:w-full laptop:w-fit rounded-full justify-center items-center flex hover:opacity-50">
                                            <p className="text-xl text-white">Купить</p>
                                        </button>
                                        <button onClick={() => addCart()} className="bg-[#EFEFEF] p-2 px-4 mobile:w-full laptop:w-fit rounded-full justify-center items-center flex hover:opacity-50">
                                            <p className="text-xl text-[#141414]">В корзину</p>
                                        </button>
                                    </div>
                                    <div className="flex flex-col mobile:space-y-1 tablet:space-y-2 mt-2">
                                        <p className="mobile:text-lg tablet:text-xl laptop:text-2xl text-[#141414] font-semibold">Описание</p>
                                        <p className="w-full mobile:text-md tablet:text-lg laptop:text-xl text-[#141414]">{productInfo.description}</p>
                                    </div>
                                    <div className="flex flex-col mobile:space-y-1 tablet:space-y-2 mt-12">
                                        <p className="mobile:text-lg tablet:text-xl laptop:text-2xl text-[#141414] font-semibold">Как это работает?</p>
                                        <p className="w-full mobile:text-md tablet:text-lg laptop:text-xl text-[#141414]">Когда вы покупаете товар на нашем маркетплейсе, система автоматически отправляет вам ссылку на скачивание на электронную почту, которую вы указали при регистрации. Это происходит сразу после успешной авторизации и завершения покупки. Таким образом, вы сможете легко получить доступ к вашему товару прямо на вашей почте.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <Form />
                </>
            )}
            <Footer />
        </div>
    );
};

export default ProductPage;
