import HistoryCard from "./HistoryCard";

function HistoryCards() {
    return (
        <div className="w-full h-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-6">
            <HistoryCard />
            <HistoryCard />
            <HistoryCard />
        </div>
    );
};

export default HistoryCards;
