import Navbar from "../ux/Navbar";
import HistoryCards from "../ux/HistoryCards";
import Footer from "../ux/Footer";

function History() {
    return (
        <div className="w-full h-full bg-[#F9F9F9]">
            <Navbar/>
            <div className="pt-28 mb-4 px-6">
                <p className="text-4xl text-[#141414] font-semibold">История покупок</p>
            </div>
            <HistoryCards/>
            <Footer/>
        </div>
    )
};

export default History;