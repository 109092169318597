import { Link, useNavigate } from "react-router-dom";
import { CiHeart } from "react-icons/ci";
import { useEffect, useState } from "react";
import { FaHeart } from "react-icons/fa";

function Card({ data }) {
    const [isFavourite, setIsFavourite] = useState(false);
    const navigate = useNavigate();

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    useEffect(() => {
        fetchIsFavourite();
    }, [isFavourite]);

    const fetchIsFavourite = async () => {
        try {
            const authToken = getCookie("auth_token");
            const response = await fetch(`https://sitepro2.kz/api/user/isFavourite/${data.product_id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${authToken}` // Добавляем токен в заголовок
                }
            });

            const responseJson = await response.json();

            if (responseJson.success) {
                setIsFavourite(responseJson.isFavourite);
            }
        } catch (error) {
            console.log('fetch is favourite error: ', error);
        }
    }

    const handleChangeFavourite = async (event) => {
        event.stopPropagation(); // Останавливаем всплытие события, чтобы избежать редиректа
        try {
            const authToken = getCookie("auth_token");

            if (authToken) {
                const response = await fetch('https://sitepro2.kz/api/user/favourite/add', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}` // Добавляем токен в заголовок
                    },
                    body: JSON.stringify({ product_id: data.product_id })
                });

                const responseJson = await response.json();

                if (responseJson.success) {
                    if (responseJson.message === 'favourite product deleted') {
                        setIsFavourite(false);
                        window.location.reload();
                    } else {
                        setIsFavourite(true);
                        window.location.reload();
                    }
                }
            } else {
                alert("Сначала авторизуйтесь")
            }
        } catch (error) {
            console.log('add favourite error: ', error);
        }
    }

    const handleCardClick = () => {
        navigate(`/product/${data.product_id}`);
    }

    return (
        <div onClick={handleCardClick} className="w-full cursor-pointer group">
            <div className="relative">
                <img
                    src={`https://sitepro2.kz/api/product/previewImage/${data.image_preview}`}
                    className="w-full mobile:h-[250px] laptop:h-[300px] desktop:h-[494px] bg-[#BDBDBD] rounded-xl object-cover"
                    alt="image-preview"
                    loading="eager"
                />
                <button
                    onClick={handleChangeFavourite}
                    className="hover:opacity-50 absolute z-10 w-10 h-10 justify-center items-center flex bg-[#EFEFEF] top-4 right-4 rounded-full"
                >
                    {isFavourite ?
                        <FaHeart className="text-2xl text-[#4534FF]" /> :
                        <CiHeart className="text-2xl" />
                    }
                </button>
            </div>
            <div className="w-full flex flex-row justify-between items-center py-1">
                <p className="mobile:text-xs tablet:text-md laptop:text-lg text-[#141414] font-semibold group-hover:text-[#4534FF]">{data.title}</p>
                <div className="flex flex-row space-x-2">
                    {data.old_price && <p className="mobile:text-md tablet:text-lg laptop:text-xl text-[#141414]/30 line-through ">{data.old_price} тг</p>}
                    <p className="mobile:text-md tablet:text-lg laptop:text-xl text-[#141414]">{data.price} тг</p>
                </div>
            </div>
        </div>
    );
};

export default Card;
