function HistoryCard({ data }) {
    return (
        <div className="w-full">
            <div className="relative">
                <img className="w-full h-[494px] bg-[#BDBDBD] rounded-xl" alt="image-preview"/>
            </div>
            <div className="w-full flex flex-col py-1">
                <p className="text-lg text-[#141414] font-semibold">BlogPro - Профессиональный блог ...</p>
                <p className="text-lg text-[#141414]/50 font-semibold">Стоимость: <span className="text-[#141414]">60 000тг</span></p>
                <p className="text-lg text-[#141414]/50 font-semibold">Дата покупки: <span className="text-[#141414]">15.06.2024</span></p>
                <p className="text-lg text-[#141414]/50 font-semibold">Номер заказа: <span className="text-[#141414]">ORD-2015-07-31-987452</span></p>
                <button className="bg-[#4534FF] justify-center items-center flex p-3 rounded-xl mt-4 hover:opacity-50">
                    <p className="text-xl text-white">Получить заказ</p>
                </button>
                <button className="border-2 border-[#4534FF] justify-center items-center flex p-3 rounded-xl mt-2 hover:opacity-50">
                    <p className="text-xl text-[#4534FF]">Страница товара</p>
                </button>
            </div>
        </div>
    );
};

export default HistoryCard;
