import Navbar from "../ux/Navbar";
import Footer from "../ux/Footer";
import { useEffect, useState, useRef } from "react";
import ReactLoading from 'react-loading';
import { IoCheckmark, IoCloseOutline  } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import EditPassword from "../ux/EditPassword";

function Profile() {
    const [userData, setUserData] = useState(null);
    const [isLoad, setIsLoad] = useState(true);
    const [data, setData] = useState({
        fullname: '',
        email: '',
        newImage: null
    });
    const fileInput = useRef(null);
    const [isSave, setIsSave] = useState(false);
    const [isEditPassword, setIsEditPassword] = useState(false);
    
    useEffect(() => {
        if (
            data.fullname !== userData?.fullname ||
            data.email !== userData?.email
        ) {
            setIsSave(true);
        } else {
            setIsSave(false);
        }
    }, [data, userData]);
    

    const handleChangeData = (e) => {
        const { name, value } = e.target;
        setData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    useEffect(() => {
        if (userData) {
            setData({
                fullname: userData.fullname || '',
                email: userData.email || ''
            });
        }
    }, [userData]);

    const fetchUserData = async () => {
        try {
            setIsLoad(true);
            const authToken = getCookie("auth_token"); 
            const response = await fetch('https://sitepro2.kz/api/user/user', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}` // Добавляем токен в заголовок
                }
            });
    
            const responseJson = await response.json();
            if (responseJson.success) {
                setUserData(responseJson.user[0]);
            } else {
                console.log('Ошибка: ', responseJson.message);
            }
        } catch (error) {
            console.log('fetch user data error: ', error);
        } finally {
            setIsLoad(false);
        }
    };

    const updatePhotoProfile = async () => {
        const authToken = getCookie("auth_token"); 
        const formData = new FormData();
        formData.append('newImage', data.newImage);

        try {
            const response = await fetch('https://sitepro2.kz/api/user/photo/update', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}` // Добавляем токен в заголовок
                },
                body: formData,
            });

            const responseJson = await response.json();

            if (responseJson.success) {
                window.location.reload();
            }

        } catch (error) {
            console.log('updatePhotoProfile: ', error);
        }
    };

    const saveEditedData = async () => {
        try {
            const authToken = getCookie("auth_token"); 
            const response = await fetch('https://sitepro2.kz/api/user/edit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}` // Добавляем токен в заголовок
                },
                body: JSON.stringify({
                    fullname: data.fullname, 
                    email: data.email
                })
            });

            const responseJson = await response.json();

            if (responseJson.success) {
                window.location.reload();
            }
        } catch (error) {
            console.log('edit data errror: ', error);
        }
    }

    return (
        <div className="w-full h-full bg-[#F9F9F9]">
            <Navbar/>
            { isLoad && (
                <div className="h-[100vh] w-full justify-center items-center flex flex-col space-y-2">
                    <ReactLoading type="spin" color="#4534FF" height={25} width={25} />
                    <p className="text-md text-[#141414]/50">Подождите, это может занять немного времени</p>
                </div>
            ) }
            { !isLoad && (
                <div className="w-full justify-between items-center p-12">
                    <p className="text-3xl text-[#141414] font-semibold mt-20">Редактирование профиля</p>
                    <div className="w-full flex flex-col justify-center items-center">
                        <div>   
                            <p className="text-[#141414] text-xl mb-4 text-center">Основная информация</p>
                            <div className="flex flex-col items-start space-x-2">
                                <div className="flex flex-col items-center justify-center w-full space-y-2">
                                    { data.newImage ? (
                                        <div className="w-32 h-32 rounded-full relative bg-[#BDBDBD]">
                                            <img src={URL.createObjectURL(data.newImage)} className="w-full h-full object-cover rounded-full" alt="profile-photo"/>
                                            <div className="flex flex-row w-full justify-between absolute z-10 bottom-0">
                                                <button onClick={() => setData(prevData => ({ newImage: null }))} className="border-4 border-white bg-red-500 h-8 w-8 justify-center items-center flex rounded-full absolute z-10 left-0 bottom-0 hover:opacity-50">
                                                    <IoCloseOutline  className="mobile:text-lg tablet:text-xl laptop:text-2xl text-white"/>
                                                </button>
                                                <button onClick={() => updatePhotoProfile()} className="border-4 border-white bg-[#44CD8D] h-8 w-8 justify-center items-center flex rounded-full absolute z-10 right-0 bottom-0 hover:opacity-50">
                                                    <IoCheckmark className="mobile:text-md tablet:text-lg laptop:text-xl text-white"/>
                                                </button>
                                            </div>
                                        </div> ) : (
                                        <div className="w-32 h-32 rounded-full relative bg-[#BDBDBD]">
                                            <img src={`https://sitepro2.kz/api/user/photo/${userData?.photo_profile}`} className="w-full h-full object-cover rounded-full" alt="profile-photo"/>
                                        </div>  
                                    ) }
                                    <input 
                                        type='file'
                                        id={`newImage`}
                                        name={`newImage`}
                                        accept="image/*" 
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            setData(prevState => ({
                                                ...prevState,
                                                newImage: file
                                            }));
                                        }} 
                                        style={{ display: "none" }} 
                                        ref={fileInput}
                                    />
                                    { !data.newImage && (
                                        <button onClick={() => fileInput.current.click()} className="hover:opacity-50">
                                            <p className="text-md text-[#141414] underline">Изменить</p>
                                        </button>
                                    ) }
                                </div>
                                
                                <div className=" w-6 bg-black line"/>
                                <div className="flex flex-col space-y-4 items-start justify-start w-72 form">
                                    <div className="w-full space-y-2">
                                        <p className="text-xl text-[#141414] font-semibold">Полное имя</p>
                                        <div className="w-full border-[1px] border-[#141414]/50 rounded-xl p-2 px-4">
                                            <input 
                                                value={data.fullname}
                                                name="fullname"
                                                onChange={handleChangeData}
                                                className="text-xl w-full text-[#141414] outline-none bg-transparent"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full space-y-2">
                                        <p className="text-xl text-[#141414] font-semibold">Электронная почта</p>
                                        <div className="w-full border-[1px] border-[#141414]/50 rounded-xl p-2 px-4">
                                            <input 
                                                value={data.email} 
                                                name="email"
                                                onChange={handleChangeData}
                                                className="text-xl w-full text-[#141414] outline-none bg-transparent"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full space-y-2">
                                        <p className="text-xl text-[#141414] font-semibold">Пароль</p>
                                        <div className="w-full border-[1px] border-[#141414]/50 rounded-xl p-2 px-4 flex flex-row justify-between items-center">
                                            <input disabled={true} value="password" type="password"  className="text-xl text-[#141414] outline-none bg-transparent"/>
                                            <button onClick={() => setIsEditPassword(true)} className="hover:opacity-50">
                                                <FiEdit className="text-xl text-[#141414]/50"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full justify-center items-center flex mt-6">
                                <button 
                                    onClick={() => saveEditedData()} 
                                    disabled={!isSave}
                                    className={`bg-[#4534FF] w-72 justify-center items-center flex flex-row rounded-xl p-3 ${ !isSave ? 'opacity-50' : 'hover:opacity-50' }`}
                                >
                                    <p className="text-white text-xl">Сохранить</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) }
            <Footer/>
            { isEditPassword && <EditPassword onClose={() => setIsEditPassword(false)}/> }
        </div>
    );
}

export default Profile;
