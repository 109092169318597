import './App.css';
import CatalogPage from './components/pages/CatalogPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProductPage from './components/pages/ProductPage';
import AboutUs from './components/pages/AboutUs';
import Contacts from './components/pages/Contacts';
import Reg from './components/pages/Reg';
import Auth from './components/pages/Auth';
import History from './components/pages/History';
import Profile from './components/pages/Profile';
import FavouritePage from './components/pages/FavouritePage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/catalog' element={<CatalogPage/>} />
        <Route path='/product/:id' element={<ProductPage/>} />
        <Route path='/about-us' element={<AboutUs/>} />
        <Route path='/contacts' element={<Contacts/>}/>
        <Route path='/reg' element={<Reg/>}/>
        <Route path='/auth' element={<Auth/>}/>
        <Route path='/history' element={<History/>}/>
        <Route path='/profile' element={<Profile/>}/>
        <Route path='/favourite' element={<FavouritePage/>}/>
      </Routes>
    </Router>
  );
}

export default App;
