import { useState } from "react";

function Form() {
    const [data, setData] = useState({
        fullname: "",
        email: "",
        phoneNumber: "",
        comment: ""
    });

    const handleChangeData = (e) => {
        const { name, value } = e.target;

        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const sendForm = async () => {
        setData({
            fullname: '',
            email: '',
            phoneNumber: '',
            comment: ''
        });
        alert("Ваша заявка успешно отправлена")
    }

    return (
        <div className="w-full flex flex-col space-y-6 p-6 mobile:px-6 tablet:px-12">
            <p className="mobile:text-xl tablet:text-2xl laptop:text-3xl desktop:text-4xl text-[#141414] font-semibold">Свяжитесь с нами для<br />консультации и поддержки!</p>
            <div className="flex justify-end items-end flex-col w-full space-y-6">
                <div className="flex mobile:flex-col laptop:flex-row mobile:space-x-0 mobile:space-y-2 tablet:space-y-0 tablet:space-x-4 mobile:items-start laptop:items-center w-full justify-end">
                    <p className="text-xl text-[#14141] font-semibold flex-nowrap text-nowrap">Полное имя</p>
                    <div className="p-2 px-4 border-[1px] border-[#141414]/50 rounded-xl mobile:w-full laptop:w-5/12">
                        <input
                            name="fullname"
                            value={data.fullname}
                            onChange={handleChangeData}
                            placeholder="Напишите полное имя"
                            className="text-lg text-semibold text-[#141414] w-full bg-transparent outline-none" />
                    </div>
                </div>
                <div className="flex mobile:flex-col laptop:flex-row mobile:space-x-0 mobile:space-y-2 tablet:space-y-0 tablet:space-x-4 mobile:items-start laptop:items-center w-full justify-end">
                    <p className="text-xl text-[#14141] font-semibold flex-nowrap text-nowrap">Email</p>
                    <div className="p-2 px-4 border-[1px] border-[#141414]/50 rounded-xl mobile:w-full laptop:w-5/12">
                        <input
                            name="email"
                            value={data.email}
                            onChange={handleChangeData}
                            placeholder="Напишите email"
                            className="text-lg text-semibold text-[#141414] w-full bg-transparent outline-none"
                        />
                    </div>
                </div>
                <div className="flex mobile:flex-col laptop:flex-row mobile:space-x-0 mobile:space-y-2 tablet:space-y-0 tablet:space-x-4 mobile:items-start laptop:items-center w-full justify-end">
                    <p className="text-xl text-[#14141] font-semibold flex-nowrap text-nowrap">Номер телефона</p>
                    <div className="p-2 px-4 border-[1px] border-[#141414]/50 rounded-xl mobile:w-full laptop:w-5/12">
                        <input
                            name="phoneNumber"
                            value={data.phoneNumber}
                            onChange={handleChangeData}
                            placeholder="Напишите номер телефона"
                            type="numeric"
                            className="text-lg text-semibold text-[#141414] w-full bg-transparent outline-none"
                        />
                    </div>
                </div>
                <div className="flex mobile:flex-col laptop:flex-row mobile:space-x-0 mobile:space-y-2 tablet:space-y-0 tablet:space-x-4 mobile:items-start laptop:items-center w-full justify-end">
                    <p className="text-xl text-[#14141] font-semibold flex-nowrap text-nowrap">Сообщение</p>
                    <div className="p-2 px-4 border-[1px] border-[#141414]/50 rounded-xl mobile:w-full laptop:w-5/12">
                        <textarea
                            name="comment"
                            value={data.comment}
                            onChange={handleChangeData}
                            placeholder="Напишите сообщение"
                            className="text-lg text-semibold text-[#141414] w-full h-48 bg-transparent outline-none"
                        />
                    </div>
                </div>
                <button
                    onClick={() => sendForm()}
                    disabled={!data.fullname || !data.email || !data.phoneNumber || !data.comment}
                    className={`bg-[#4534FF] p-4 mobile:w-full tablet:w-5/12 rounded-full flex justify-center items-center ${!data.fullname || !data.email || !data.phoneNumber || !data.comment ? 'opacity-50' : 'hover:opacity-50'}`}
                >
                    <p className="text-xl text-white">Отправить</p>
                </button>
            </div>
        </div>
    )
};

export default Form;