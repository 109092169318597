import { useState } from "react";
import { IoMdCheckmark } from "react-icons/io";
import { useNavigate } from "react-router-dom";

function Reg() {
    const [data, setData] = useState({
        fullname: "",
        email: "",
        password: ""
    });
    const [message, setMessage] = useState();
    const [isCheckbox, setIsCheckbox] = useState(false);
    const navigate = useNavigate();

    const reg = async () => {
        try {
            const response = await fetch(`https://sitepro2.kz/api/user/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fullname: data.fullname,
                    email: data.email,
                    password: data.password
                })
            });

            const responseJson = await response.json();

            if (responseJson.success) {
                setMessage();
                document.cookie = `auth_token=${responseJson.auth_token}; path=/`;
                navigate('/catalog');
            } else {
                setMessage(responseJson.message);
            }
        } catch (error) {
            console.log(`user reg error: ${error}`);
        }
    }

    const handleChangeData = (e) => {
        const { name, value } = e.target;
        setData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <div className="h-screen w-full bg-[#F9F9F9] flex justify-center items-center">
            <div className="w-96 h-screen absolute z-10 left-0 bg-[#191353] justify-between mobile:hidden desktop:flex flex-col">
                <div className="flex-1 p-6 py-24">
                    <p className="text-3xl text-white font-semibold">Добро пожаловать<br />в SitePro</p>
                    <p className="text-xl text-white mt-4">Здесь вы можете пройти простую регистрацию для дальнейшего взаимодействия с платформой</p>
                </div>
                <img src={require("../../img/pc.png")} alt="pc" />
            </div>
            <div className="flex flex-col mobile:w-80 desktop:w-96">
                <p className="text-xl text-[#141414] font-semibold text-center">Регистрация</p>
                <div className="flex flex-col space-y-2 mt-6">
                    <div className="space-y-1">
                        <p className="text-lg text-[#141414] font-semibold">Полное имя</p>
                        <div className="w-full border-[1px] border-[#141414]/50 rounded-xl p-2 px-4">
                            <input value={data.fullname} name="fullname" onChange={handleChangeData} placeholder="Введите ваше полное имя" className="text-lg text-[#141414] w-full bg-transparent outline-none" />
                        </div>
                    </div>
                    <div className="space-y-1">
                        <p className="text-lg text-[#141414] font-semibold">Электронная почта</p>
                        <div className="w-full border-[1px] border-[#141414]/50 rounded-xl p-2 px-4">
                            <input value={data.email} name="email" onChange={handleChangeData} placeholder="Введите ваше полное имя" className="text-lg text-[#141414] w-full bg-transparent outline-none" />
                        </div>
                    </div>
                    <div className="space-y-1">
                        <p className="text-lg text-[#141414] font-semibold">Пароль</p>
                        <div className="w-full border-[1px] border-[#141414]/50 rounded-xl p-2 px-4">
                            <input
                                value={data.password}
                                name="password"
                                onChange={handleChangeData}
                                type="password"
                                placeholder="Введите ваше полное имя"
                                className="text-lg text-[#141414] w-full bg-transparent outline-none"
                            />
                        </div>
                    </div>
                    <div className="flex flex-row space-x-2">
                        <button
                            onClick={() => setIsCheckbox(!isCheckbox)}
                            className={
                                isCheckbox ?
                                    "hover:opacity-50 rounded-md w-6 h-6 flex-shrink-0 bg-[#4534FF] flex justify-center items-center" :
                                    "hover:opacity-50 rounded-md w-6 h-6 flex-shrink-0 bg-[#EFEFEF]"
                            }
                        >
                            {isCheckbox && <IoMdCheckmark className="text-white" />}
                        </button>
                        <p className="text-xl text-[#141414]/50">Я даю согласие на обработку моих <a href="https://sitepro2.kz/api/user/documents/privacy-policy.pdf" className="text-[#141414]">Персональных данных</a></p>
                    </div>
                </div>
                <p className="text-xl text-red-500 my-4 text-center">{message}</p>
                <button
                    disabled={!data.email || !data.fullname || !data.password || !isCheckbox}
                    onClick={() => reg()}
                    className={
                        !data.email || !data.fullname || !data.password || !isCheckbox ?
                            "w-full rounded-xl p-4 justify-center items-center flex bg-[#4534FF] opacity-50" :
                            "w-full rounded-xl p-4 justify-center items-center flex bg-[#4534FF] hover:opacity-50"
                    }
                >
                    <p className="text-xl text-white">Зарегистрироваться</p>
                </button>
                <p className="text-center text-xl text-[#141414]/50 mt-2">У вас уже есть аккаунт? <span className="text-[#141414] underline hover:opacity-50 cursor-pointer" onClick={() => navigate('/auth')}>Войти</span></p>
            </div>
        </div>
    )
};

export default Reg;