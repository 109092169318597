import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Auth() {
    const [data, setData] = useState({
        email: "",
        password: ""
    });
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const handleChangeData = (e) => {
        const { name, value } = e.target;
        setData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const auth = async () => {
        try {
            const response = await fetch(`https://sitepro2.kz/api/user/auth`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    login: data.email,
                    password: data.password
                })
            });

            const responseJson = await response.json();

            if (responseJson.success) {
                setMessage();
                document.cookie = `auth_token=${responseJson.auth_token}; path=/`;
                navigate('/catalog');
            } else {
                setMessage(responseJson.message);
            }
        } catch (error) {
            console.log('auth error: ', error);
        }
    }

    return (
        <div className="h-screen w-full bg-[#F9F9F9] flex justify-center items-center">
            <div className="w-96 h-screen absolute z-10 left-0 bg-[#191353] justify-between mobile:hidden desktop:flex flex-col">
                <div className="flex-1 p-6 py-24">
                    <p className="text-3xl text-white font-semibold">Добро пожаловать<br/>в SitePro</p>
                    <p className="text-xl text-white mt-4">Здесь вы можете авторизоваться и вернуться к вашему профилю</p>
                </div>
                <img src={require("../../img/pc.png")} alt="pc"/>
            </div>
            <div className="flex flex-col mobile:w-80 desktop:w-96">
                <p className="text-xl text-[#141414] font-semibold text-center">Вход</p>
                <div className="flex flex-col space-y-2 mt-6">
                    <div className="space-y-1">
                        <p className="text-lg text-[#141414] font-semibold">Электронная почта</p>
                        <div className="w-full border-[1px] border-[#141414]/50 rounded-xl p-2 px-4">
                            <input 
                                placeholder="Введите электронную почту" 
                                className="text-lg text-[#141414] w-full bg-transparent outline-none"
                                value={data.email}
                                name="email"
                                onChange={handleChangeData}
                            />
                        </div>
                    </div>
                    <div className="space-y-1">
                        <p className="text-lg text-[#141414] font-semibold">Пароль</p>
                        <div className="w-full border-[1px] border-[#141414]/50 rounded-xl p-2 px-4">
                            <input
                                placeholder="Пароль" 
                                className="text-lg text-[#141414] w-full bg-transparent outline-none"
                                value={data.password}
                                name="password"
                                type="password"
                                onChange={handleChangeData}
                            />
                        </div>
                    </div>
                </div>
                <p className="text-xl text-red-500 text-center my-4">{message}</p>
                <button 
                    disabled={!data.email || !data.password}
                    className={`w-full rounded-xl p-4 justify-center items-center flex bg-[#4534FF] ${ !data.email || !data.password ? 'opacity-50' : 'hover:opacity-50' }`}
                    onClick={() => auth()}
                >
                    <p className="text-xl text-white">Войти</p>
                </button>
                <p className="text-center text-xl text-[#141414]/50 mt-2">Нет аккаунт? <span className="text-[#141414] underline hover:opacity-50 cursor-pointer" onClick={() => navigate('/reg')}>Зарегистрироваться</span></p>
            </div>
        </div>
    )
};

export default Auth;