import Form from "../ux/Form";
import Navbar from "../ux/Navbar"
import { FaTelegram, FaWhatsapp, FaInstagram, FaFacebook } from "react-icons/fa";
import Footer from "../ux/Footer";

function Contacts() {
    return (
        <div className="w-full bg-[#F9F9F9] h-full">
            <Navbar />
            <div className="w-full flex justify-between items-center mobile:px-6 tablet:px-12 mobile:pt-24 tablet:pt-28 py-6">
                <div className="flex flex-col space-y-6">
                    <p className="text-4xl text-[#141414] font-semibold">Контакты</p>
                    <div className="flex mobile:flex-col mobile:space-y-2 tablet:space-y-0 mobile:space-x-0 tablet:flex-row mobile:items-start tablet:items-center tablet:space-x-6">
                        <div>
                            <p className="text-2xl text-[#141414] text-left">Казахстан, г.Алматы<br /> ул.Хусаинова 24</p>
                        </div>
                        <div className="space-y-4">
                            <p className="text-2xl text-[#141414] text-left">+7 707 255 5522</p>
                        </div>
                    </div>
                    <div className="flex mobile:flex-col mobile:space-y-2 tablet:space-y-0 mobile:space-x-0 tablet:flex-row mobile:items-start tablet:items-center tablet:space-x-6">
                        <div>
                            <p className="text-2xl text-[#141414] text-left">Понедельник - Пятница:<br /> 9:00 - 18:00</p>
                            <p className="text-2xl text-[#141414] text-left">Суббота - Воскресенье:<br /> 10:00 - 16:00</p>
                        </div>
                        <div className="space-y-4">
                            <p className="text-2xl text-[#141414]">info@gfl.kz</p>
                            {/* <div className="flex flex-row space-x-4">
                                <button className="h-12 w-12 bg-[#EFEFEF] flex justify-center items-center rounded-full hover:opacity-50">
                                    <FaTelegram className="text-3xl text-[#4534FF]" />
                                </button>
                                <button className="h-12 w-12 bg-[#EFEFEF] flex justify-center items-center rounded-full hover:opacity-50">
                                    <FaWhatsapp className="text-3xl text-[#4534FF]" />
                                </button>
                                <button className="h-12 w-12 bg-[#EFEFEF] flex justify-center items-center rounded-full hover:opacity-50">
                                    <FaInstagram className="text-3xl text-[#4534FF]" />
                                </button>
                                <button className="h-12 w-12 bg-[#EFEFEF] flex justify-center items-center rounded-full hover:opacity-50">
                                    <FaFacebook className="text-3xl text-[#4534FF]" />
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
                <img src={require("../../img/contacts.png")} alt="contact-image" className="mobile:hidden laptop:block" />
            </div>
            <Form />
            <Footer />
        </div>
    )
};

export default Contacts