function Footer() {
    return (
        <div className="w-full mt-12 bg-cover bg-center mobile:h-fit tablet:h-[362px]" style={{ backgroundImage: `url(${require("../../img/footer-background.png")})` }}>
            <div className="w-full h-full p-6 flex flex-col justify-between">
                <div className="w-full flex mobile:flex-col tablet:flex-row mobile:space-y-12 tablet:space-y-0 mobile:space-x-0 tablet:space-x-16 laptop:space-x-48">
                    <div className="h-full flex justify-between items-start flex-col mobile:space-y-2 laptop:space-y-6">
                        <p className="mobile:text-lg tablet:text-2xl text-white font-semibold">Контакты</p>
                        <div className="flex flex-row space-x-6">
                            <div className="flex flex-col items-start mobile:space-y-2 laptop:space-y-4">
                                <p className="mobile:text-md tablet:text-lg text-white text-left">+7 707 255 5522</p>
                                <p className="mobile:text-md tablet:text-lg text-white text-left">info@gfl.kz</p>
                                <p className="mobile:text-md tablet:text-lg text-white text-left">Казахстан, г.Алматы<br /> ул.Хусаинова 24</p>
                            </div>
                            <div className="flex flex-col items-start mobile:space-y-2 laptop:space-y-4">
                                <a href="https://instagram.com" className="hover:opacity-50">
                                    <p className="mobile:text-md tablet:text-lg text-white text-left">instagram</p>
                                </a>
                                <a href="https://x.com" className="hover:opacity-50">
                                    <p className="mobile:text-md tablet:text-lg text-white text-left">X</p>
                                </a>
                                <a href="https://facebook.com" className="hover:opacity-50">
                                    <p className="mobile:text-md tablet:text-lg text-white text-left">Facebook</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="h-full flex justify-between items-start flex-col mobile:space-y-2 laptop:space-y-6">
                        <p className="mobile:text-lg tablet:text-2xl text-white font-semibold">Компания</p>
                        <div className="flex flex-col space-y-2">
                            <div className="flex flex-row space-x-6 mobile:text-md tablet:text-lg text-white text-left">
                                <p>Компания</p>
                                <p>TOO GFFinance</p>
                            </div>
                            <div className="flex flex-row space-x-6 mobile:text-md tablet:text-lg text-white text-left">
                                <p>Руководитель</p>
                                <p>Батыров Канат Булатович</p>
                            </div>
                            <div className="flex flex-row space-x-6 mobile:text-md tablet:text-lg text-white text-left">
                                <p>БИН</p>
                                <p>230740025394</p>
                            </div>
                            <div className="flex flex-row space-x-6 mobile:text-md tablet:text-lg text-white text-left">
                                <p>Расчетный счет</p>
                                <p>KZ6596502F0016417576</p>
                            </div>
                            <div className="flex flex-row space-x-6 mobile:text-md tablet:text-lg text-white text-left">
                                <p>Банк</p>
                                <p>АО "ForteBank"</p>
                            </div>
                            <div className="flex flex-row space-x-6 mobile:text-md tablet:text-lg text-white text-left">
                                <p>БИК</p>
                                <p>IRTYKZKA</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex mobile:mt-12 tablet:mt-0 mobile:flex-col tablet:flex-row mobile:items-start justify-start  tablet:items-center mobile:space-y-4 tablet:space-y-0 mobile:space-x-0 tablet:space-x-6">
                    <p className="text-lg text-white/70">© Copyright 2024 Site.pro</p>
                    <a href="https://sitepro2.kz/api/user/documents/privacy-policy.pdf" className="text-lg text-white/70 hover:opacity-50">Политика конфиденциальности</a>
                    <a href="https://sitepro2.kz/api/user/documents/public-offer.pdf" className="text-lg text-white/70 hover:opacity-50">Публичная Оферта</a>
                    <a href="https://sitepro2.kz/api/user/documents/return-policy.pdf" className="text-lg text-white/70 hover:opacity-50">Политика возврата</a>
                    <a href="https://sitepro2.kz/api/user/documents/payment-terms.pdf" className="text-lg text-white/70 hover:opacity-50">Условие оплаты</a>
                </div>
            </div>
        </div>
    );
}

export default Footer;
